<template>
    <!-- 整体背景 -->
    <div class="login-wrap">
        <!--输入框-->
        <div class="form-wrapper">
        <div class="header">
            欢迎使用网上点餐系统
        </div>
        <div class="input-wrapper">
            <div class="border-wrapper">
            <input type="text" name="username" v-model="username" placeholder="请输入用户名" class="border-item" autocomplete="off" />
            </div>
            <div class="border-wrapper">
            <input type="password" name="password" v-model="password" placeholder="请输入密码" class="border-item" autocomplete="off" />
            </div>
        </div>
        <div class="action">
            <div class="btn" @click="login()">登 录</div>
        </div>
        </div>
    </div>
</template>

<script>
  import {login,getUser} from '../../../api/api' 
  import { setLock } from '@/utils/lock'
  export default {
    data() {
      return{
        username: '',
        password: ''
      }
    },
    methods: {
        login() {
            if(!this.username) {
                this.$message({
                    message: '请输入用户名',
                    type: 'warning'
                });
                return;
            }
            if(!this.password) {
                this.$message({
                    message: '请输入密码',
                    type: 'warning'
                });
                return;
            }
            var params = {
                username: this.username,
                password: this.password,
                userType: 1
            }
            login(params).then(res => {
                if(res.code == 1000) {
                    this.$message({
                        message: '登陆成功',
                        type: 'success'
                    });
                    var that = this
                    var token = res.data.token
                    this.$store.commit('user/setToken', token)
                    this.getUserInfo()
                    setLock(0)
                    setTimeout(function() {
                        that.$router.push("/index")
                    },500)
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        getUserInfo() {
            getUser().then(res => {
                if(res.code == 1000) {
                    this.$store.commit('user/setUser', JSON.stringify(res.data))
                }
            })
        },
    },
    created() {
     
    },
    mounted() {
      
    }
 }
</script>

<style lang=scss scoped>
  .login-wrap {
  height: 100%;
  font-family: JetBrains Mono Medium;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #0e92b3; */
  background: url('../../../assets/image/login-back.jpeg');
  background-size: 100% 100%;
}
 
.form-wrapper {
  width: 300px;
  background-color: rgba(41, 45, 62, 0.8);
  color: #fff;
  border-radius: 2px;
  padding: 50px;
}
 
.form-wrapper .header {
  text-align: center;
  font-size: 25px;
  line-height: 100px;
  font-family: '黑体';
}
 
.form-wrapper .input-wrapper input {
  background-color: rgb(41, 45, 62);
  border: 0;
  width: 100%;
  text-align: center;
  font-size: 15px;
  color: #fff;
  outline: none;
}
 
.form-wrapper .input-wrapper input::placeholder {
  text-transform: uppercase;
}
 
.form-wrapper .input-wrapper .border-wrapper {
  background-image: linear-gradient(to right, #e8198b, #0eb4dd);
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.form-wrapper .input-wrapper .border-wrapper .border-item {
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  border-radius: 30px;
}
 
.form-wrapper .action {
  display: flex;
  justify-content: center;
}
 
.form-wrapper .action .btn {
  width: 60%;
  text-transform: uppercase;
  border: 2px solid #0e92b3;
  text-align: center;
  line-height: 50px;
  border-radius: 30px;
  cursor: pointer;
}
 
.form-wrapper .action .btn:hover {
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}
 
.form-wrapper .icon-wrapper {
  text-align: center;
  width: 60%;
  margin: 0 auto;
  margin-top: 20px;
  border-top: 1px dashed rgb(146, 146, 146);
  padding: 20px;
}
 
.form-wrapper .icon-wrapper i {
  font-size: 20px;
  color: rgb(187, 187, 187);
  cursor: pointer;
  border: 1px solid #fff;
  padding: 5px;
  border-radius: 20px;
}
 
.form-wrapper .icon-wrapper i:hover {
  background-color: #0e92b3;
}
</style>