import request, { post, get } from "@/utils/request";

// export function products(params) {
//     return request({
//       url: "/api/v1/products",  //接口路径
//       method: "get",  //接口方法
//       headers: { 'Content-Type': 'multipart/form-data' }, //给接口添加请求头
//       params  //接口参数
//     });
// }

//-------------------------------登陆---------------------------------------
// 登陆
export const login = (params) => post("/login",params)
//登出
export const logout = () => get("/login/logout")

//-------------------------------上传---------------------------------------
// 上传图片
export const uploadImage = (params) => post("/common/uploadImage",params)
// 上传文件
export const uploadFile = (params) => post("/common/uploadFile",params)

//-------------------------------部门---------------------------------------
// 查询部门
export const getDeptList = (params) => post('/dept/getDeptList',params)
// 新增部门
export const saveDept = (params) => post('/dept/saveDept',params)
// 根据id查询部门
export const getById = (params) => get('/dept/getById',params)
//更新部门
export const editDept = (params) => post("/dept/editDept",params)
//删除部门
export const removeDept = (params) => get("/dept/removeDept",params)

//-------------------------------菜单---------------------------------------
// 查询菜单
export const getMenuList = (params) => post("/menu/getMenuList",params)
//保存菜单
export const saveMenu = (params) => post("/menu/saveMenu",params)
//更新菜单
export const editMenu = (params) => post("/menu/editMenu",params)
//删除菜单
export const removeMenu = (params) => get("/menu/removeMenu",params)
//根据id查询菜单
export const getMenuById = (params) => get("/menu/getById",params)
//根据用户获取菜单权限
export const getMenuByUser = () => get("/menu/getMenuByUser")

//-------------------------------岗位---------------------------------------
// 查询岗位
export const getPostPage = (params) => post("/post/getPostPage",params)
//岗位列表
export const getPostList = (params) => post("/post/getPostList",params)
//保存岗位
export const savePost = (params) => post("/post/savePost",params)
//更新岗位
export const editPost = (params) => post("/post/editPost",params)
//删除岗位
export const removePost = (params) => get("/post/removePost",params)
//根据id查询岗位
export const getPostById = (params) => get("/post/getPostById",params)

//-------------------------------角色---------------------------------------
// 查询角色
export const getRolePage = (params) => post("/role/getRolePage",params)
//查询角色列表
export const getRoleList = (params) => post("/role/getRoleList",params)
//根据id获取角色
export const getRoleById = (params) => get("/role/getRoleById",params)
//保存角色
export const saveRole = (params) => post("/role/saveRole",params)
//更新角色
export const editRole = (params) => post("/role/editRole",params)
//删除角色
export const removeRole = (params) => get("/role/removeRole",params)

//-------------------------------用户---------------------------------------
// 查询用户
export const getUserPage = (params) => post("/user/getUserPage",params)
//根据id查询用户
export const getUserById = (params) => get("/user/getUserById",params)
//保存用户
export const saveUser = (params) => post("/user/saveUser",params)
//更新用户
export const editUser = (params) => post("/user/editUser",params)
//删除用户
export const removeUser = (params) => get("/user/removeUser",params)
//重置密码
export const resetPassword = (params) => post("/user/resetPassword",params)
//获取登陆用户信息
export const getUser = () => get("/user/getUserInfo")
//修改个人信息
export const setUserInfo = (params) => post("/user/setUserInfo",params)
//修改个人头像
export const setUserAvatar = (params) => get("/user/setUserAvatar",params)
//验证密码
export const verPassword = (params) => get("/login/verPassword",params)

//-------------------------------参数---------------------------------------
// 查询参数
export const getParamPage = (params) => post("/param/getParamPage",params)
// 根据id查询参数
export const getParamById = (params) => get("/param/getById",params)
//保存参数
export const saveParam = (params) => post("/param/saveParam",params)
//更新参数
export const editParam = (params) => post("/param/editParam",params)
//删除参数
export const removeParam = (params) => get("/param/removeParam",params)

//-------------------------------公告---------------------------------------
// 查询公告
export const getAccountPage = (params) => post("/account/getAccountPage",params)
// 根据id查询公告
export const getAccountById = (params) => get("/account/getById",params)
// 保存公告
export const saveAccount = (params) => post("/account/saveAccount",params)
// 编辑公告
export const editAccount = (params) => post("/account/editAccount",params)
//删除公告
export const removeAccount = (params) => get("/account/removeAccount",params)

//-------------------------------日志---------------------------------------
// 查询登陆日志
export const getLogPage = (params) => post("/loginLog/getLogPage",params)
// 删除登陆日志
export const removeLog = (params) => get("/loginLog/removeLog",params)
// 清空登陆日志
export const clearLog = () => get("/loginLog/clearLog")
// 查询操作日志
export const getOperLogPage = (params) => post("/operLog/getLogPage",params)
// 删除操作日志
export const removeOperLog = (params) => get("/operLog/removeLog",params)
// 清空操作日志
export const clearOperLog = () => get("/operLog/clearLog")

//-------------------------------字典---------------------------------------
// 查询字典
export const getDictPage = (params) => post("/dict/getDictPage",params)
// 根据id获取字典
export const getDictById = (params) => get("/dict/getDictById",params)
// 保存字典
export const saveDict = (params) => post("/dict/saveDict",params)
// 编辑字典
export const editDict = (params) => post("/dict/editDict",params)
// 删除字典
export const removeDict = (params) => get("/dict/removeDict",params)
// 根据编码获取字典数据
export const getDictByCode = (params) => get("/dict/getDictByCode",params)
// 根据编码获取字典数据和标签获取值
export const getDictByCodeAndLabel = (params) => get("/dict/getDictByCodeAndLabel",params)
// 查询全部字典类型
export const getDictTypeList = () => get("/dict/getDictTypeList")

//-------------------------------代码生成---------------------------------------
// 查询代码生成列表
export const getGenPage = (params) => post("/genTable/getGenPage",params)
// 根据id获取代码生成
export const getGenById = (params) => get("/genTable/getGenById",params)
// 保存代码生成
export const saveGen = (params) => get("/genTable/saveGen",params)
// 编辑代码生成
export const editGen = (params) => post("/genTable/editGen",params)
// 删除代码生成
export const removeGen = (params) => get("/genTable/removeGen",params)
// 获取数据库表
export const getTables = (params) => post("/genTable/getTables",params)
// 同步生成表和字段
export const syncTableAndColumns = (params) => get("/genTable/syncTableAndColumns",params)
//预览
export const preview = (params) => get("/genTable/preview",params)

//-------------------------------省份---------------------------------------
//查询省份
export const getApeProvincePage = (params) => post("/province/getApeProvincePage",params)
//根据id查询省份
export const getApeProvinceById = (params) => get("/province/getApeProvinceById",params)
//保存省份
export const saveApeProvince = (params) => post("/province/saveApeProvince",params)
//更新省份
export const editApeProvince = (params) => post("/province/editApeProvince",params)
//删除ApeProvince
export const removeApeProvince = (params) => get("/province/removeApeProvince",params)
//获取列表
export const getApeProvinceList = (params) => get("/province/getApeProvinceList",params)

//-------------------------------城市---------------------------------------
//查询城市
export const getApeCityPage = (params) => post("/city/getApeCityPage",params)
//根据id查询城市
export const getApeCityById = (params) => get("/city/getApeCityById",params)
//保存城市
export const saveApeCity = (params) => post("/city/saveApeCity",params)
//更新城市
export const editApeCity = (params) => post("/city/editApeCity",params)
//删除ApeCity
export const removeApeCity = (params) => get("/city/removeApeCity",params)
//获取列表
export const getCityList = (params) => get("/city/getCityList",params)

//-------------------------------商圈---------------------------------------
//查询商圈
export const getApeBusinessPage = (params) => post("/business/getApeBusinessPage",params)
//根据id查询商圈
export const getApeBusinessById = (params) => get("/business/getApeBusinessById",params)
//保存商圈
export const saveApeBusiness = (params) => post("/business/saveApeBusiness",params)
//更新商圈
export const editApeBusiness = (params) => post("/business/editApeBusiness",params)
//删除ApeBusiness
export const removeApeBusiness = (params) => get("/business/removeApeBusiness",params)
//列表
export const getApeBusinessList = (params) => get("/business/getApeBusinessList",params)

//-------------------------------分类---------------------------------------
//查询分类
export const getApeTypePage = (params) => post("/type/getApeTypePage",params)
//根据id查询分类
export const getApeTypeById = (params) => get("/type/getApeTypeById",params)
//保存分类
export const saveApeType = (params) => post("/type/saveApeType",params)
//更新分类
export const editApeType = (params) => post("/type/editApeType",params)
//删除ApeType
export const removeApeType = (params) => get("/type/removeApeType",params)
//获取列表
export const getTypeList = (params) => get("/type/getTypeList",params)

//-------------------------------标签---------------------------------------
//查询标签
export const getApeTagPage = (params) => post("/tag/getApeTagPage",params)
//根据id查询标签
export const getApeTagById = (params) => get("/tag/getApeTagById",params)
//保存标签
export const saveApeTag = (params) => post("/tag/saveApeTag",params)
//更新标签
export const editApeTag = (params) => post("/tag/editApeTag",params)
//删除ApeTag
export const removeApeTag = (params) => get("/tag/removeApeTag",params)
//获取列表
export const getTagList = (params) => get("/tag/getTagList",params)

//-------------------------------美食资讯---------------------------------------
//查询美食资讯
export const getApeArticlePage = (params) => post("/article/getApeArticlePage",params)
//根据id查询美食资讯
export const getApeArticleById = (params) => get("/article/getApeArticleById",params)
//保存美食资讯
export const saveApeArticle = (params) => post("/article/saveApeArticle",params)
//更新美食资讯
export const editApeArticle = (params) => post("/article/editApeArticle",params)
//删除ApeArticle
export const removeApeArticle = (params) => get("/article/removeApeArticle",params)

//-------------------------------美食点评---------------------------------------
//查询美食点评
export const getApeCommentPage = (params) => post("/comment/getApeCommentPage",params)
//根据id查询美食点评
export const getApeCommentById = (params) => get("/comment/getApeCommentById",params)
//保存美食点评
export const saveApeComment = (params) => post("/comment/saveApeComment",params)
//更新美食点评
export const editApeComment = (params) => post("/comment/editApeComment",params)
//删除ApeComment
export const removeApeComment = (params) => get("/comment/removeApeComment",params)

//-------------------------------留言---------------------------------------
//查询留言
export const getApeMessagePage = (params) => post("/message/getApeMessagePage",params)
//根据id查询留言
export const getApeMessageById = (params) => get("/message/getApeMessageById",params)
//保存留言
export const saveApeMessage = (params) => post("/message/saveApeMessage",params)
//更新留言
export const editApeMessage = (params) => post("/message/editApeMessage",params)
//删除ApeMessage
export const removeApeMessage = (params) => get("/message/removeApeMessage",params)

//-------------------------------美食---------------------------------------
//查询美食
export const getApeFoodPage = (params) => post("/food/getApeFoodPage",params)
export const getApeFoodPageByShop = (params) => post("/food/getApeFoodPageByShop",params)
//根据id查询美食
export const getApeFoodById = (params) => get("/food/getApeFoodById",params)
//保存美食
export const saveApeFood = (params) => post("/food/saveApeFood",params)
//更新美食
export const editApeFood = (params) => post("/food/editApeFood",params)
//删除ApeFood
export const removeApeFood = (params) => get("/food/removeApeFood",params)
//获取商家列表
export const getUserListByType = (params) => get("/user/getUserListByType",params)

//-------------------------------美食评论---------------------------------------
//查询美食评论
export const getApeFoodCommentPage = (params) => post("/comment/getApeFoodCommentPage",params)
//根据id查询美食评论
export const getApeFoodCommentById = (params) => get("/comment/getApeFoodCommentById",params)
//保存美食评论
export const saveApeFoodComment = (params) => post("/comment/saveApeFoodComment",params)
//更新美食评论
export const editApeFoodComment = (params) => post("/comment/editApeFoodComment",params)
//删除ApeFoodComment
export const removeApeFoodComment = (params) => get("/comment/removeApeFoodComment",params)

//-------------------------------订单---------------------------------------
//查询订单
export const getApeOrderPage = (params) => post("/order/getApeOrderPage",params)
export const getApeOrderPageByShop = (params) => post("/order/getApeOrderPageByShop",params)
//根据id查询订单
export const getApeOrderById = (params) => get("/order/getApeOrderById",params)
//保存订单
export const saveApeOrder = (params) => post("/order/saveApeOrder",params)
//更新订单
export const editApeOrder = (params) => post("/order/editApeOrder",params)
//删除ApeOrder
export const removeApeOrder = (params) => get("/order/removeApeOrder",params)

export const editApeOrderState = (params) => get("/order/editApeOrderState",params)

//-------------------------------地址---------------------------------------
//查询地址
export const getApeAddressPage = (params) => post("/address/getApeAddressPage",params)
//根据id查询地址
export const getApeAddressById = (params) => get("/address/getApeAddressById",params)
//保存地址
export const saveApeAddress = (params) => post("/address/saveApeAddress",params)
//更新地址
export const editApeAddress = (params) => post("/address/editApeAddress",params)
//删除ApeAddress
export const removeApeAddress = (params) => get("/address/removeApeAddress",params)

export const getIndexData = (params) => get("/login/getIndexData",params)

export const getShopIndexData = (params) => get("/login/getShopIndexData",params)

export const changeOpen = (params) => get("/user/changeOpen",params)

export const getSale = (params) => post("/food/getSale",params)

export const getUserSalePage = (params) => post("/user/getUserSalePage",params)

export const getUserDianCaiPage = (params) => post("/user/getUserDianCaiPage",params)