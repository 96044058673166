const configure = {
    state:{
        HOST: 'http://localhost:8080',  //后台访问地址根目录
    },
    getters: {
              
    },
    mutations: {
        
    }
}

export default configure